/* @font-face {
    font-family: 'HelvethaicaMon';
    font-style: normal;
    font-weight: 400;
    src: local('HelvethaicaMon'), url('../assets/fonts/HelvethaicaMon/db_helvethaicamon_x_v3.2-webfont.woff') format('woff')
}
   */

@font-face {
  font-family: Boon;
  src: url(../assets/fonts/woff2-otf/Boon-Light.woff2) format("woff2"), url(../assets/fonts/woff-otf/Boon-Light.woff) format("woff"), url(../assets/fonts/otf/Boon-Light.otf) format("truetype");
  font-weight: 300;
  font-style: normal; }
.boon-300 {
  font-family: Boon;
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: Boon;
  src: url(../assets/fonts/woff2-otf/Boon-LightItalic.woff2) format("woff2"), url(../assets/fonts/woff-otf/Boon-LightItalic.woff) format("woff"), url(../assets/fonts/otf/Boon-LightItalic.otf) format("truetype");
  font-weight: 300;
  font-style: italic; }
.boon-300i {
  font-family: Boon;
  font-weight: 300;
  font-style: italic; }

@font-face {
  font-family: Boon;
  src: url(../assets/fonts/woff2-otf/Boon-Regular.woff2) format("woff2"), url(../assets/fonts/woff-otf/Boon-Regular.woff) format("woff"), url(../assets/fonts/otf/Boon-Regular.otf) format("truetype");
  font-weight: 400;
  font-style: normal; }
.boon-400 {
  font-family: Boon;
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: Boon;
  src: url(../assets/fonts/woff2-otf/Boon-Italic.woff2) format("woff2"), url(../assets/fonts/woff-otf/Boon-Italic.woff) format("woff"), url(../assets/fonts/otf/Boon-Italic.otf) format("truetype");
  font-weight: 400;
  font-style: italic; }
.boon-400i {
  font-family: Boon;
  font-weight: 400;
  font-style: italic; }

@font-face {
  font-family: Boon;
  src: url(../assets/fonts/woff2-otf/Boon-Medium.woff2) format("woff2"), url(../assets/fonts/woff-otf/Boon-Medium.woff) format("woff"), url(../assets/fonts/otf/Boon-Medium.otf) format("truetype");
  font-weight: 500;
  font-style: normal; }
.boon-500 {
  font-family: Boon;
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: Boon;
  src: url(../assets/fonts/woff2-otf/Boon-MediumItalic.woff2) format("woff2"), url(../assets/fonts/woff-otf/Boon-MediumItalic.woff) format("woff"), url(../assets/fonts/otf/Boon-MediumItalic.otf) format("truetype");
  font-weight: 500;
  font-style: italic; }
.boon-500i {
  font-family: Boon;
  font-weight: 500;
  font-style: italic; }

@font-face {
  font-family: Boon;
  src: url(../assets/fonts/woff2-otf/Boon-SemiBold.woff2) format("woff2"), url(../assets/fonts/woff-otf/Boon-SemiBold.woff) format("woff"), url(../assets/fonts/otf/Boon-SemiBold.otf) format("truetype");
  font-weight: 600;
  font-style: normal; }
.boon-600 {
  font-family: Boon;
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: Boon;
  src: url(../assets/fonts/woff2-otf/Boon-SemiBoldItalic.woff2) format("woff2"), url(../assets/fonts/woff-otf/Boon-SemiBoldItalic.woff) format("woff"), url(../assets/fonts/otf/Boon-SemiBoldItalic.otf) format("truetype");
  font-weight: 600;
  font-style: italic; }
.boon-600i {
  font-family: Boon;
  font-weight: 600;
  font-style: italic; }

@font-face {
  font-family: Boon;
  src: url(../assets/fonts/woff2-otf/Boon-Bold.woff2) format("woff2"), url(../assets/fonts/woff-otf/Boon-Bold.woff) format("woff"), url(../assets/fonts/otf/Boon-Bold.otf) format("truetype");
  font-weight: 700;
  font-style: normal; }
.boon-700 {
  font-family: Boon;
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: Boon;
  src: url(../assets/fonts/woff2-otf/Boon-BoldItalic.woff2) format("woff2"), url(../assets/fonts/woff-otf/Boon-BoldItalic.woff) format("woff"), url(../assets/fonts/otf/Boon-BoldItalic.otf) format("truetype");
  font-weight: 700;
  font-style: italic; }
.boon-700i {
  font-family: Boon;
  font-weight: 700;
  font-style: italic; }

@font-face {
    font-family: TH-Baijam;
    src: url(../assets/fonts/TH-Baijam-Italic.ttf) format("ttf"), url(../assets/fonts/TH-Baijam-Bold-Italic.ttf) format("tff"), url(../assets/fonts/otf/Boon-BoldItalic.otf) format("truetype");
    font-style: normal;
    font-weight: 100;
}

/*# sourceMappingURL=boon-all.css.map */
