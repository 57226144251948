@import 'assets/styles/_custom.scss';
@import '~@fortawesome/fontawesome-free/css/all.min.css';

body {
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-family: 'Boon', sans-serif;
    -webkit-font-smoothing: antialiased;
}

h2 {
    font-size: 20px;
}

button {
    width: 100px;
    height: 36px;
    font-size: 14px !important;
}
